<script>
export default {
  computed: {
    orderHistory() {
      return () => import(`@/components/Themes/${this.$cookies.get('theme') ?? 'default'}/OrderHistory/List`);
    }
  },
  methods: {},
};
</script>
<template>
  <component :is="orderHistory" v-if="orderHistory"></component>
</template>